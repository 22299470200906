import axios, { AxiosRequestConfig } from 'axios'
import { Button, DatePicker } from 'components/common'
import { useState } from 'react'
import './ActiveLicensesReport.scss'
import { useParams } from 'react-router-dom'

function ActiveLicensesReportWithLogins() {
  const { partner_id } = useParams<{
    partner_id?: string
  }>()
  const [downloading, setDownloading] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(() => {
    const now = new Date()
    return new Date(now.getFullYear(), now.getMonth() + 1, now.getDate())
  })

  const convertToUTCDate = (date: Date) => {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    ).toISOString()
  }

  const handleSubmit = async () => {
    setDownloading(true)
    const requestOptions: AxiosRequestConfig<{
      start_date: string
      end_date: string
    }> = {
      method: 'post',
      responseType: 'blob',
      baseURL: process.env.REACT_APP_NODE_SERVICE_URL,
      url: `/pdf-service/mcr-admin/${partner_id}/active-licenses-report-w-logins`,
      data: {
        start_date: convertToUTCDate(startDate),
        end_date: convertToUTCDate(endDate),
      },
    }
    const { data } = await axios(requestOptions)

    const element = document.createElement('a')
    element.href = URL.createObjectURL(data)
    element.download = `active_licenses_report.pdf`
    document.body.appendChild(element)
    element.click()

    setDownloading(false)
  }

  return (
    <div>
      <h4>Active Licenses Report w/ Logins</h4>
      <div className="active-licenses-report-container">
        <label htmlFor="start-date">Start Date:</label>
        <DatePicker
          id="start-date"
          className="start-date"
          placeholderText="start date..."
          selected={startDate}
          onChange={setStartDate}
        />
        <div className="end-date-container">
          <label htmlFor="end-date">End Date:</label>
          <DatePicker
            id="end-date"
            className="end-date"
            placeholderText="end date..."
            selected={endDate}
            onChange={setEndDate}
          />
        </div>
        <Button
          color="green outline"
          className="get-report-button"
          loading={downloading}
          onClick={handleSubmit}
        >
          Get Report
        </Button>
      </div>
    </div>
  )
}

export default ActiveLicensesReportWithLogins
